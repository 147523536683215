.contact-user-modal .modal-header .close > * {
  color: rgba(0, 0, 0, 0.45);
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}

.contact-user-modal .modal-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.contact-user-modal-title {
  font-size: 16px;
}
