@import "~antd/dist/antd.less";

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.ant-table-filter-dropdown-btns {
  .ant-btn-primary,
  .ant-btn-link {
    display: flex;
    align-items: center;
  }
}

.ant-btn,
.ant-btn[disabled],
.ant-btn[type="button"]:not(:disabled) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-modal .ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
}

@root-entry-name: default;@primary-color: #5a2fb1;@table-row-hover-bg: rgba(0,0,0,.05);@table-header-sort-bg: rgba(0,0,0,.03);@table-body-sort-bg: rgba(0,0,0,.03);@font-family: Roboto;@code-family: Roboto Mono;