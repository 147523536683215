.business-asset-confirmation-modal .modal-header {
  border-bottom-width: 0;
  justify-content: flex-end;
}

.business-asset-confirmation-modal .modal-header .close {
  float: none;
  background-color: #ebe7f7;
  color: #5a2fb1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  opacity: 1;
  font-size: 20px;
  font-weight: normal;
}

.business-asset-confirmation-modal .modal-body {
  padding: 20px 40px;
}

.business-asset-confirmation-modal .modal-footer {
  flex-direction: column;
  border-top-width: 0;
  margin-bottom: 20px;
}

.business-asset-confirmation-modal .modal-footer > * {
  margin-top: 12px;
}
