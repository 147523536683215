#root {
  height: 100%;
}

.light {
  color: #9b9b9b;
}

.emphasized {
  font-weight: bold;
}

table.ustva {
  margin-top: 25px;
  width: 100%;
}

table.ustva thead,
table.ustva tfoot {
  text-align: center;
  background: #999999;
  color: #ffffff;
}

table.ustva tr:hover {
  background: #eeeeee;
}

table.ustva td {
  padding: 5px 20px;
}

table.ustva td:nth-child(2) {
  font-family: "Roboto Mono";
  text-align: right;
  font-size: 16px;
}

/** Redefenition of bootstrap vars **/
:root {
  --font-family-sans-serif: Roboto;
  --font-family-monospace: "Roboto Mono";
}

body {
  font-family: Roboto;
}

.tooltip {
  font-family: Roboto;
}

.popover {
  font-family: Roboto;
}

pre,
code,
kbd,
samp {
  font-family: "Roboto Mono";
}

.text-monospace {
  font-family: "Roboto Mono" !important;
}
